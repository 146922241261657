import React from 'react';
import Card from './Card';
import './Cards.css';

const Cards = () => {
  const dummyData = [
    {
      title: 'Your opinion is valued and Rewarded',
      points: [
        'Earn by helping introduce new selection',
        'Earn by grading the selection',
        'Earn by making informative content about the products',
      ],
      image:
        'https://res.cloudinary.com/dfbih3qxn/image/upload/v1680848953/disagree_gzu6qk.png',
    },
    {
      title: 'Everyone is an ELITE customer',
      points: [
        'Free less than 2 day delivery',
        'No minimum orders',
        'Free seamless returns experience',
      ],
      image:
        'https://res.cloudinary.com/dfbih3qxn/image/upload/v1680848953/royalty_nfm3n5.png',
    },
    {
      title: 'Quality  Selection',
      points: [
        'Community graded selection to ensure only high quality',
        'products are maintained in the platform',
      ],
      image:
        'https://res.cloudinary.com/dfbih3qxn/image/upload/v1680848953/selected_uoln2a.png',
    },
    {
      title: 'Competitive Pricing',
      points: [
        'Our first of a kind supply chain design is aimed at lowering the cost to help you provide competitive pricing.',
      ],
      image:
        'https://res.cloudinary.com/dfbih3qxn/image/upload/v1680848953/save_pz5dqa.png',
    },
  ];

  return (
    <div className='row mt-5 '>
      {dummyData.map((data, index) => (
        <Card
          key={index}
          title={data.title}
          points={data.points}
          image={data.image}
          // className={index === dummyData.length - 1 ? '' : 'me-3'}
        />
      ))}
    </div>
  );
};

export default Cards;
