const LeftColumn = () => {
  return (
    <div className='col-12 col-md-6'>
      {' '}
      {/* Update the class to col-12 col-md-6 */}
      <div className='hero-content'>
        <h1>People Driven Ecommerce</h1>
        {/* <p>Product Delivery is Easier Ever</p> */}
        <p>&nbsp;</p>
        <p>
          Join us in the journey of building a people-driven ecommerce
          ecosystem! We're on a mission to change the way people shop online. By
          putting people at the heart of our ecosystem, we're creating a truly
          collaborative and innovative experience that empowers buyers. Join us
          today!
        </p>

        <div
          style={{
            marginTop: '5rem',
          }}
          className='mb-5 d-flex  justify-content-center align-items-center 
    justify-content-xl-between custom-margin'
        >
          <a
            target='_blank'
            href='https://apps.apple.com/app/project-we/id6447542965'
          >
            <img
              style={{
                // borderRadius: '1rem',
                width: '10rem',
              }}
              src='https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg'
              alt='Available on the App Store'
            />
          </a>
          <div className='d-none d-lg-flex justify-content-center align-items-center'>
            <p className='me-4 mt-3'>See Demo</p>

            <div className='circle-container'>
              <div className='circle-back'></div>
              <div className='circle-front'>
                <i className='fas fa-arrow-right'></i>
              </div>
            </div>
            <img
              style={{
                width: '7rem',
                position: 'relative',
                right: '-5rem',
                top: '-2rem',
              }}
              src={require('./../Images/curlyArrow.png')}
              alt='arrow'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftColumn;
