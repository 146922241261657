// Card.tsx
import React from 'react';
import './Card.css';

interface CardProps {
  title: string;
  points: string[];
  image: string;
  className?: string;
}

const Card: React.FC<CardProps> = ({ title, points, image, className }) => {
  return (
    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
      <div className={`card-container position-relative ${className}`}>
        <div className='d-flex justify-content-center'>
          <img
            style={{
              height: '5rem',
              width: '5rem',
              marginBottom: '1rem',
              // center image in the same row

              // position: 'absolute',
              // top: '-1rem',
              right: '0',
            }}
            src={image}
            alt='card image'
          />
        </div>
        <p className=' fw-bold  '>{title}</p>
        {points && (
          <ul className='card-points'>
            {points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default Card;
