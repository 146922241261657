import './Hero.css';

const RightColumn = () => {
  return (
    <div className='col-12 col-md-6'>
      {' '}
      {/* Update the class to col-12 col-md-6 */}
      <div className='hero-content'>
        <div className='image-container d-flex justify-content-center'>
          <img
            style={{
              width: '6rem',
              position: 'absolute',
              left: '50%',
            }}
            src={require('./../Images/scribble.png')}
            alt='right image'
            className='scribble-dots'
          />
          <img
            style={{
              width: '6rem',
              position: 'absolute',
              right: '25%',
              top: '40%',
            }}
            src={require('./../Images/dots.png')}
            alt='right image'
            className='scribble-dots'
          />

          <div className='image-container position-relative'>
            <div className='bg'>
              <img
                className='image'
                src={require('./../Images/reward.png')}
                alt='reward image'
              />
            </div>
            <div className='bg' style={{ zIndex: 2 }}>
              <video
                style={{
                  border: 'none',
                  marginLeft: '-0.1rem',
                }}
                className='image'
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src='https://res.cloudinary.com/dfbih3qxn/video/upload/v1680848176/webDemo_dpnena.mov'
                  type='video/mp4'
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightColumn;
