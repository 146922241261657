// Header.tsx

import React from 'react';
import './Header.css';
import HamburgerMenu from './HamburgerMenu';

const Header = () => {
  return (
    <header className='d-flex justify-content-between align-items-center py-3'>
      <div className='logo-container'>
        <span className='project zen'>
          Project W
          <img
            style={{
              width: '20px',
              height: '33px',
              position: 'relative',
              top: '-2px',
            }}
            src={require('./hamburger.png')}
          />
          <p className='tagline courier'>Working together to meet your needs</p>
        </span>
      </div>
      <nav className='header-links d-xl-flex d-none'>
        <a className='me-5' href='#home'>
          Home
        </a>
        <a target='_blank' href='/privacy-policy.html'>
          Privacy Policy
        </a>
      </nav>
      <HamburgerMenu>
        <nav className='header-links'>
          <a href='#home'>Home</a>
          <a href='/privacy-policy.html'>Privacy Policy</a>
        </nav>
      </HamburgerMenu>
    </header>
  );
};

export default Header;
