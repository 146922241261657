import React from 'react';
import './App.css';
import LeftColumn from './Hero/LeftColumn';
import Header from './Header/Header';
import RightColumn from './Hero/RightColumn';
import Cards from './HomeCards/Cards';

const App: React.FC = () => {
  return (
    <div className='App '>
      <section className='container pt-2'>
        <Header />
        <div className='row mt-0 mt-xl-5'>
          <LeftColumn />
          <RightColumn />
        </div>
        {/* <div className=''> */}
          <Cards />
        {/* </div> */}
      </section>
    </div>
  );
};

export default App;
