import React, { useState, useEffect } from 'react';
import './HamburgerMenu.css';

interface HamburgerMenuProps {
  children: React.ReactNode;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    console.log('toggleMenu', !isOpen);
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // useEffect(() => {
  //   // Add event listener to window to close menu when clicking outside
  //   window.addEventListener('click', closeMenu);

  //   // Remove event listener when component unmounts
  //   return () => {
  //     window.removeEventListener('click', closeMenu);
  //   };
  // }, []);

  return (
    <>
      <div className='hamburger-menu' onClick={toggleMenu}>
        <img style={{ width: '25px' }} src={require('./menu.png')} alt='menu' />
      </div>
      {isOpen && (
        <div onClick={toggleMenu} className='menu-container'>
          {children}
        </div>
      )}
    </>
  );
};

export default HamburgerMenu;
